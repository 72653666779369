import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Firmware Configuration and Software Installation",
  "description": "Both firmware and software are an important part of the production design process. Factors such as efficiency, functionality, and simplicity need to be fully considered.",
  "author": "Stan Luo",
  "categories": ["turnkey"],
  "date": null,
  "featured": false,
  "tags": ["turnkey"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Since the market for new tech is continuously expanding, the demand for electronic products has also been increasing. This means software developments for electronic products are also becoming more and more important. It's one of the most crucial steps in the production of electronic products, and is indispensable.`}</p>
    <p>{`The rapid development of electronic technology has led to the emergence of various types of `}<strong parentName="p">{`controllers (MCUs)`}</strong>{` on the market, followed by the emergence of various `}<strong parentName="p">{`programming software and test software`}</strong>{` to adapt to the development of different controllers. `}</p>
    <p>{`At the same time, different products and different applications have `}<strong parentName="p">{`different firmware`}</strong>{` needs to be configured. This not only brings difficulty to the front-end developers but also brings a daunting challenge to the back-end production testing.`}</p>
    <h2 {...{
      "id": "firmware-configuration"
    }}>{`Firmware Configuration`}</h2>
    <p>{`The so-called `}<strong parentName="p">{`Firmware`}</strong>{` is the program written in `}<strong parentName="p">{`EPROM`}</strong>{` or `}<strong parentName="p">{`EEPROM`}</strong>{`, and the popular understanding is `}<strong parentName="p">{`"solidified software"`}</strong>{`. To put it more simply, Firmware is `}<strong parentName="p">{`BIOS`}</strong>{` software, but it is completely different from ordinary software. It is the program code solidified inside the integrated circuit, which is responsible for `}<strong parentName="p">{`controlling and coordinating the functions of the integrated circuit`}</strong>{`.`}</p>
    <p>{`Firmware refers to the device "driver" stored inside the device. Through firmware, the `}<strong parentName="p">{`operating system can realize the operation of a specific machine according to the standard device driver`}</strong>{`. For example, CD-ROM drives and recorders all have internal firmware.`}</p>
    <p>{`Firmware acts as the software for the most basic and bottom-level work of a system. In hardware devices, `}<strong parentName="p">{`firmware is the soul of hardware devices`}</strong>{`, because some hardware devices have no other software components except firmware, so firmware also determines the function and performance of hardware devices.`}</p>
    <p>{`This being said the configuration of firmware should be a crucial point when designing and manufacturing products. Firmware needs to be built very precisely and carefully. Sure, a lot of devices now allow some kind of firmware upgrade, but there are still many devices that, once used, could only run with that particular version of the firmware. `}</p>
    <p>{`At the same time, designers should consider the `}<strong parentName="p">{`ease of firmware download to reduce the difficulty and complexity of production`}</strong>{`. In the products that NexPCB has served in the past, we always negotiate with customers and suggest that customers modify and improve the process and operation mode of configuring/downloading firmware, to make the production and testing easier, and speed up the time of product launch. `}</p>
    <h2 {...{
      "id": "software-installation"
    }}>{`Software Installation`}</h2>
    <p>{`Whenever we do product development, we always encounter different software that needs to be installed at various stages of product development. `}</p>
    <p>{`In product design, we need `}<strong parentName="p">{`different IDEs`}</strong>{` to program and debug the microcontroller. During product testing, we need various software to download code to verify our functionality. However, various IDEs also bring huge challenges to production.`}</p>
    <p>{`We always lean towards using universal and accessible IDEs for development verification and functional testing. This helps us to identify unknown problems easily since niche IDEs tend to have limited resources. `}</p>
    <p>{`NexPCB is committed to optimizing the manufacturability of products and accelerating product realization. We are constantly seeking the best solution for firmware configuration and software installation. Assuring that the time needed in product development and verification process can be spent as efficient as possible. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      